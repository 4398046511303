import log from "./log";
import { includes } from "lodash";

export const Feature = {
  RATES_TABLE: "RATES_TABLE",
  MOCK_APIS: "MOCK_APIS",
  AUTH_AND_CASE_MANAGEMENT: "AUTH_AND_CASE_MANAGEMENT"
};

const initialEnabledFeatures = (process.env.INITIAL_FEATURES || "")
  .split(",")
  .filter(feature => includes(Object.keys(Feature), feature))
  .reduce(
    (featuresAccumulator, enabledFeature) => ({
      ...featuresAccumulator,
      [enabledFeature]: true
    }),
    {}
  );

const isSsr = () => {
  if (typeof window === "undefined") {
    return true;
  }
  return false;
};

if (!isSsr()) {
  const LOCAL_STORAGE_KEY = "features";

  window.features = {
    getFeatures() {
      const features = JSON.parse(sessionStorage.getItem(LOCAL_STORAGE_KEY));
      log.debug("getFeatures", features);
      return features;
    },

    saveFeatures(features) {
      sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(features));
      log.debug("saveFeatures", features);
    },

    toggleFeature(feature) {
      const features = this.getFeatures();
      const newFeatures = {
        ...features,
        [feature]: !features[feature]
      };
      this.saveFeatures(newFeatures);
      log.debug("toggleFeature", feature);
    },

    resetFeatures() {
      this.saveFeatures(initialEnabledFeatures);
      log.debug("resetFeatures");
    }
  };

  if (window.features.getFeatures() === null) {
    window.features.resetFeatures();
  }
}

export function isEnabled(feature) {
  let features;

  if (!isSsr()) {
    features = window.features.getFeatures();
  } else {
    features = process.env.initialEnabledFeatures;
  }

  if (!features) {
    return false;
  }

  return !!features[feature];
}
