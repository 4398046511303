import React from "react";
import { withPrefix } from "gatsby";
import { Redirect } from "@reach/router";
import { isEnabled, Feature } from "../common/features";

export default function ErrorPage() {
  const excludedEnvs = ["production", "staging", "uat"];
  if (excludedEnvs.includes(process.env.GATSBY_ACTIVE_ENV)) {
    return <Redirect to={withPrefix("/404")} noThrow />;
  }

  return (
    <form>
      {Object.values(Feature).map(feature => (
        <label key={feature} className="block">
          {feature}
          <input
            name={feature}
            type="checkbox"
            defaultChecked={isEnabled(feature)}
            onClick={() => window.features.toggleFeature(feature)}
          />
        </label>
      ))}
    </form>
  );
}
